@tailwind base;
@tailwind components;
@tailwind utilities;


@layer utilities {
    @variants responsive {
      .-text-indent-2 {
        text-indent: -2rem;
      }
      .-text-indent-8 {
        text-indent: -2rem;
      }
    }
  }